import React from "react";
import Model from "../assets/bitcoin.glb"; // Adjust the path as needed
import Models from "../components/Models";
import { FaArrowRightLong } from "react-icons/fa6";

const CoverPage = () => {
  return (
    <div className="h-full z-10">
      <div className=" h-full flex flex-col-reverse md:flex-row justify-center md:justify-between items-center lg:mx-32 mx-6 gap-5 md:gap-0">
        <div>
          <h1 class="text-center md:text-left text-4xl font-extrabold leading-none tracking-tight text-[#F7931A] md:text-6xl lg:text-7xl ">
            Decentralized
          </h1>
          <h1 class="text-center md:text-left text-3xl font-extrabold leading-none tracking-tight text-[#F7931A] md:text-4xl lg:text-5xl ">
            Oracle Network
          </h1>
          <p className="text-center md:text-left mt-2 text-white md:text-xl text-sm">
            for Bitcoin Ecosystem{" "}
          </p>
          <div className="flex justify-center lg:justify-start">
            <button
              type="button"
              class="md:mt-16 mt-8 text-white hover:border gradient-background font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              <span className=" text-xs md:text-base flex flex-row items-center gap-2 align-baseline">
                Explore Donbit Technology
                <FaArrowRightLong color="#F7931A" />
              </span>
            </button>
          </div>
        </div>
        {/* <Models path={Model} /> */}
      </div>
    </div>
  );
};

export default CoverPage;
