import React from "react";
import logo from "../assets/donbitLogo.png";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";

const Header = () => {
  const [open, setOpen] = React.useState(false);
  const toggleMenu = () => {
    setOpen(!open);
  };
  return (
    // <div className="px-6 lg:px-12 py-4 flex justify-between bg-transparent fixed top-0 w-full z-50">
    <nav className=" bg-transparent z-50 fixed top-0 w-full">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4 py-2">
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} alt className="lg:w-[12rem] w-[8rem]" />
        </a>
        <button
          onClick={toggleMenu}
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg lg:hidden   "
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          {open ? (
            <IoMdClose size={32} />
          ) : (
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          )}
        </button>
        <div
          className={`${open ? "block" : "hidden"} w-full lg:block lg:w-auto`}
          id="navbar-default"
        >
          <ul className="font-medium flex flex-col p-4 lg:p-0 mt-4 bg-orange-500 bg-opacity-10 rounded-2xl shadow-lg shadow-black/10 backdrop-blur-lg border border-orange-500 border-opacity-50  lg:bg-transparent lg:bg-opacity-100 lg:rounded-none lg:shadow-none lg:backdrop-blur-none lg:flex-row lg:space-x-8 rtl:space-x-reverse lg:mt-0 lg:border-0  ">
            <li>
              <a
                href="#"
                className="block py-2 px-3 text-white rounded hover:bg-[#F6931B] lg:hover:bg-transparent lg:border-0 lg:hover:text-[#F6931B] lg:p-0 "
              >
                Products
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block py-2 px-3 text-white rounded hover:bg-[#F6931B] lg:hover:bg-transparent lg:border-0 lg:hover:text-[#F6931B] lg:p-0 "
              >
                Dev Tools
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block py-2 px-3 text-white rounded hover:bg-[#F6931B] lg:hover:bg-transparent lg:border-0 lg:hover:text-[#F6931B] lg:p-0 "
              >
                Docs
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block py-2 px-3 text-white rounded hover:bg-[#F6931B] lg:hover:bg-transparent lg:border-0 lg:hover:text-[#F6931B] lg:p-0 "
              >
                Ecosystem
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block py-2 px-3 text-white rounded hover:bg-[#F6931B] lg:hover:bg-transparent lg:border-0 lg:hover:text-[#F6931B] lg:p-0 "
              >
                Network
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    // <div>
    //   <ul className="flex flex-row gap-5 text-white items-center h-full">
    //     <li>Products</li>
    //     <li>Dev Tools</li>
    //     <li>Docs</li>
    //     <li>Ecosystem</li>
    //     <li>Network</li>
    //   </ul>
    // </div>
    // </div>
  );
};

export default Header;
