import Header from "./components/Header";
import FooterSection from "./sections/FooterSection";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import HomePage from "./pages/HomePage";
import { Route, Routes } from "react-router-dom";
import BlogsPage from "./pages/BlogsPage";
import BlogPage from "./pages/BlogPage";
function App() {
  return (
    <div className="App h-screen  bg-black">
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/blogs/:id" element={<BlogPage />} />
        <Route path="/blogs" element={<BlogsPage />} />
      </Routes>

      <FooterSection />
    </div>
  );
}

export default App;
