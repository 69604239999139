import React from "react";

const TitleDescriptionTemplate = (props) => {
  return (
    <div className=" flex flex-col py-2 text-white ">
      <div>
        <img className="w-10 h-10 md:w-24 md:h-24" src={props.icon} />
      </div>
      <div className="text-xl text-[#F6931B] mt-2 font-bold flex flex-row gap-1">
        <div>{props.title}</div>
        {props.title === "OAAS" || props.title === "MSHC Consensus" ? (
          <div className="translate-y-[-8px]">®</div>
        ) : null}
      </div>
      <div>{props.description}</div>
    </div>
  );
};

export default TitleDescriptionTemplate;
