import React from "react";
import whiteLogo from "../assets/whiteLogo.svg";
import {
  FaLinkedinIn,
  FaXTwitter,
  FaGithub,
  FaTelegram,
} from "react-icons/fa6";
import { Link } from "react-router-dom";

const FooterSection = () => {
  return (
    <div className=" bg-black ">
      <div className="h-full glassmorphism   text-white">
        {/* <div className="border-t-2 border-[#627078]/40  p-6">
          <div className=" flex flex-row justify-between items-end">
            <img src={whiteLogo} alt="" />
            <div className="flex flex-row text-[#F6931B] gap-5">
              <a href="https://t.me/donbitorg">
                <FaTelegram size={25} />
              </a>
              <a href="#">
                <FaGithub size={25} />
              </a>
              <a href="https://x.com/DonBit_">
                <FaXTwitter size={25} />
              </a>
              <a href="https://www.linkedin.com/company/donbit/">
                <FaLinkedinIn size={25} />
              </a>
            </div>
          </div>
          <div className=" flex flex-row justify-between mt-5">
            <p className="text-[#F5F5F5]/40">
              Copyright ©2024 DONBIT. All rights reserved
            </p>
            <div className="text-[#F5F5F5]/40 flex flex-row gap-5">
              <div>Terms of Use</div>
              <div>Privacy Policy</div>
            </div>
          </div>
        </div> */}
        <div className="px-6 lg:px-32 ">
          <div className="flex flex-row md:flex-row justify-between md:items-center mb-5 gap-2 border-t-2 border-[#627078]/40 py-6">
            <div className="">
              <img src={whiteLogo} alt="" className="w-[10rem]" />
            </div>
            <div>
              <div>
                <div className="font-bold text-xs lg:text-base text-[#F6931B] mb-2">
                  Socials
                </div>
                <div className="flex flex-row text-[#F6931B] gap-2">
                  <a href="https://t.me/donbitorg" target="_blank">
                    <FaTelegram size={25} />
                  </a>
                  <a href="#" target="_blank">
                    <FaGithub size={25} />
                  </a>
                  <a href="https://x.com/DonBit_" target="_blank">
                    <FaXTwitter size={25} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/donbit/"
                    target="_blank"
                  >
                    <FaLinkedinIn size={25} />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className=" flex flex-row justify-between mb-5 text-xs lg:text-base">
            <div className="flex flex-col lg:flex-row gap-1">
              <p className="text-[#F5F5F5]/40">Copyright ©2024 DONBIT.</p>
              <p className="text-[#F5F5F5]/40">All rights reserved</p>
            </div>
            <div className="text-[#F5F5F5]/40 flex lg:flex-row flex-col lg:gap-5 gap-1">
              <Link to="/blogs">Blogs</Link>
              <Link to="/terms-of-use">Terms of Use</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
