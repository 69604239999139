import React from "react";
import { useParams } from "react-router-dom";
import { blogs } from "../sections/blogs";

const BlogPage = () => {
  const { id } = useParams();
  const blog = blogs.find((blog) => blog.id === id);

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <div className="min-h-screen bg-black ">
      <div className="flex flex-col min-h-screen glassmorphism pt-24">
        <div className=" lg:mt-0 mb-5 mx-6 lg:mx-32 orange-glassmorphism">
          {/* <div className="relative font-bold text-[#F6931B] text-center text-xl md:text-4xl ">
            {blog.title}
          </div> */}
          <div className=" mx-2 my-2 lg:mx-6 lg:my-6 ">
            <img
              src={blog.thumbnail}
              alt=""
              className="h-[300px] lg:h-[500px] w-full object-cover mb-5 rounded-lg"
            />
          </div>
          <div
            id="content"
            className="flex-grow mb-10 mx-2 lg:mx-6  text-white text-justify"
            dangerouslySetInnerHTML={{ __html: blog.content }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
