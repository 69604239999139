import React from "react";
import OnChain from "../components/architecture/OnChain";
import OffChain from "../components/architecture/OffChain";
import DON from "../components/architecture/DON";
import Arrow from "../components/architecture/Arrow";
import User from "../components/architecture/User";
import SOA from "../components/architecture/SOA";

const ArchitectureSection = () => {
  return (
    <div className="bg-black ">
      <div className=" glassmorphism lg:h-screen text-white flex flex-col justify-center items-center">
        <div className="flex flex-row gap-3 mt-10 lg:mt-0">
          <div className="text-3xl md:text-6xl text-white ">DONBIT </div>
          <div className="relative font-bold text-[#F6931B] text-3xl md:text-6xl w-fit">
            Architecture
            <div className="absolute bottom-0.5 w-full rounded-full h-[8px] bg-[#F6931B]/30"></div>
          </div>
        </div>
        <div className="w-full px-6 flex lg:flex-row flex-col justify-center items-center">
          <OffChain />
          <Arrow />
          <DON />
          <Arrow />
          <SOA />
          <div className="flex flex-row lg:flex-col justify-center items-center">
            <div>
              <div className="text-center text-base">API</div>
              <div className="text-center text-base">Adopters</div>
            </div>

            <Arrow size={20} />
          </div>

          <OnChain />
          <User />
        </div>
      </div>
    </div>
  );
};

export default ArchitectureSection;
