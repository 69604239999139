import React from "react";
import eclipse from "../assets/Ellipse.png";
import ocean from "../assets/blueOcean.svg";

const BlueOcean = () => {
  return (
    <div className="relative lg:h-screen flex justify-center lg:items-end my-10">
      <div className="lg:orange-glassmorphism border-0  mt-10 lg:mt-0 lg:mb-8 mx-6">
        <div className="text-white text-4xl font-bold">Blue Ocean</div>
        <img src={ocean} alt="" />
      </div>
      <img
        src={eclipse}
        alt=""
        className="scale-x-[-1] h-[600px] w-[600px] absolute object-cover lg:bottom-0 lg:right-0"
      />
    </div>
  );
};

export default BlueOcean;
