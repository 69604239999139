import React from "react";
import logo from "../assets/glow.png";
import TitleDescriptionTemplate from "../components/TitleDescriptionTemplate";
import decentralised from "../assets/features/feature_decentralised.svg";
import consensus from "../assets/features/feature_consensus.svg";
import oaas from "../assets/features/feature_oaas.svg";
import soa from "../assets/features/feature_soa.svg";
import trustless from "../assets/features/feature_trustless.svg";

const FeatureSection = () => {
  const features = [
    {
      title: "Decentralized",
      description: "True decentralization with no single control.",
      icon: decentralised,
    },
    {
      title: "Trustless",
      description: "Guaranteed accuracy from incentivized providers.",
      icon: trustless,
    },
    {
      title: "MSHC Consensus",
      description:
        "Merged Staking Hybrid Consensus for Donbit Decentralized Oracle Staking.",
      icon: consensus,
    },
    {
      title: "OAAS",
      description: "Oracle As A Service.",
      icon: oaas,
    },
    {
      title: "SOA Architecture",
      description:
        "Service Oriented Architecture (SOA) with Modularity, Interoperability, and Efficiency.",
      icon: soa,
    },
  ];
  return (
    <div className="h-full z-20 glassmorphism">
      <div className="h-full flex justify-between items-center mx-2 lg:mx-32 ">
        <div className="mt-16 flex flex-col lg:ml-16  lg:grid lg:grid-cols-3 gap-10 mb-5">
          <div className="mx-2">
            <div className="text-3xl lg:text-6xl text-white ">Key</div>
            <div className="relative font-bold text-[#F6931B] text-3xl lg:text-6xl w-fit">
              Features
              <div className="absolute bottom-0.5 w-full rounded-full h-[8px] bg-[#F6931B]/30"></div>
            </div>
          </div>
          {features.map((feature, index) => (
            <div className="mx-2 orange-glassmorphism glow">
              <TitleDescriptionTemplate
                key={index}
                title={feature.title}
                description={feature.description}
                icon={feature.icon} // Make sure your TitleDescriptionTemplate component accepts and handles this prop.
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
