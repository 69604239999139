import React, { useState } from "react";

const FAQ = () => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestionIndex(openQuestionIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is Donbit, and how does it work?",
      answer:
        "Donbit is a decentralized oracle network built on the Bitcoin blockchain. It provides secure and reliable data feeds that enable smart contracts to interact with real-world data. Donbit’s architecture leverages a network of independent nodes to fetch, verify, and transmit data to the Bitcoin blockchain. These oracles use cryptographic techniques and consensus mechanisms to ensure data integrity and prevent tampering. By integrating Donbit's services, smart contracts on Bitcoin can access accurate and timely information from various external sources.",
    },
    {
      question:
        "How does Donbit ensure the security and integrity of the data provided by its oracles?",
      answer:
        "Donbit employs multiple layers of security to ensure the integrity and reliability of its data feeds. Key security measures include:\n\n1. Cryptographic Signatures: Each data point is signed cryptographically to verify its authenticity.\n2. Trusted Execution Environments (TEEs): TEEs isolate and protect data processing to prevent unauthorized access.\n3. Redundancy and Consensus: Data is validated through multiple independent nodes and cross-referenced using a consensus mechanism to ensure accuracy and eliminate single points of failure.\n4. Tamper-Proof Data Feeds: Once data is confirmed and recorded on the Bitcoin blockchain, it becomes immutable and tamper-proof, ensuring long-term integrity.",
    },
    {
      question:
        "What are the primary use cases for Donbit’s decentralized oracle network?",
      answer:
        "Donbit's oracles can be applied across various industries to enhance smart contract functionalities. Key use cases include:\n\n- Decentralized Finance (DeFi): Providing real-time price feeds and financial data for DeFi applications, enabling automated trading, lending, and asset management.\n- Insurance: Triggering automated payouts based on verified real-world events, such as weather conditions or natural disasters.\n- Supply Chain Management: Ensuring transparency and traceability by feeding verifiable data about goods' movement and conditions into smart contracts.\n- Prediction Markets: Enabling accurate and fair resolution of prediction market outcomes by sourcing reliable event data.",
    },
    {
      question:
        "How can developers integrate Donbit’s oracles into their blockchain applications?",
      answer:
        "Developers can integrate Donbit’s oracles into their blockchain applications through the following steps:\n\n1. API Access: Use Donbit’s comprehensive API documentation to access and utilize data feeds.\n2. SDKs and Libraries: Implement the provided SDKs and libraries to streamline the integration process.\n3. Technical Guides: Follow detailed technical guides and examples available in the developer section of the Donbit website.\n4. Dashboard: Utilize Donbit’s oracle dashboard to monitor data feed performance and manage integrations.\n5. Community Support: Engage with the Donbit developer community through forums and support channels for assistance and collaboration.",
    },
    {
      question:
        "What sets Donbit apart from other decentralized oracle solutions?",
      answer:
        "Donbit differentiates itself from other oracle solutions through several unique features:\n\n- Built on Bitcoin: Unlike many oracles built on other blockchains, Donbit leverages the security and robustness of the Bitcoin network.\n- Enhanced Security: Utilizing advanced cryptographic techniques, TEEs, and a decentralized validation process, Donbit ensures unparalleled data integrity and security.\n- Scalability: Designed to handle high volumes of data requests efficiently, making it suitable for large-scale applications.\n- Reliability: By employing a network of independent nodes and a consensus mechanism, Donbit minimizes the risk of single points of failure and ensures consistent data availability.\n- Transparency: Donbit’s operations and data feeds are fully transparent, with verifiable records on the Bitcoin blockchain, enhancing trust and accountability.",
    },
  ];

  return (
    <div className="flex flex-col lg:flex-row glassmorphism flex-grow">
      <div className="w-full lg:w-1/2">
        <div className="flex lg:flex-col flex-row gap-3 mt-10 lg:mt-0 p-10 lg:mx-32">
          <div className="text-3xl md:text-6xl text-white">DONBIT</div>
          <div className="relative font-bold text-[#F6931B] text-3xl md:text-6xl w-fit">
            FAQ
            <div className="absolute bottom-0.5 w-full rounded-full h-[8px] bg-[#F6931B]/30"></div>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-2/3 p-10">
        {faqItems.map((item, index) => (
          <div key={index} className="mb-5">
            <div
              className={`flex justify-between items-center cursor-pointer orange-glassmorphism-2 text-white p-5 gap-2  ${
                openQuestionIndex === index ? "rounded-t-lg" : "rounded-lg"
              }`}
              onClick={() => toggleQuestion(index)}
            >
              <h3 className=" text-lg lg:text-2xl">{item.question}</h3>
              <span className="text-xl lg:text-3xl text-white">
                {openQuestionIndex === index ? "-" : "+"}
              </span>
            </div>
            {openQuestionIndex === index && (
              <div className="orange-glassmorphism-3 p-5 text-base lg:text-lg text-white rounded-b-lg radial-bg">
                {item.answer.split("\n").map((line, i) => (
                  <p key={i}>{line}</p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
