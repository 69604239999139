// src/components/FloatingNodes.js
import React, { useEffect, useRef } from "react";
import Matter from "matter-js";
import DONIcon from "../assets/nodes.svg";

const FloatingNodes = () => {
  const sceneRef = useRef(null);

  useEffect(() => {
    const engine = Matter.Engine.create({
      // Set gravity to zero
      gravity: { x: 0, y: 0 },
    });
    const render = Matter.Render.create({
      element: sceneRef.current,
      engine: engine,
      options: {
        // width: window.innerWidth,
        // height: window.innerHeight,
        width: 200,
        height: 200,
        wireframes: false,
        background: "transparent",
      },
    });

    const centerNode = Matter.Bodies.circle(
      //   window.innerWidth / 2,
      //   window.innerHeight / 2,
      100,
      100,
      30,
      {
        isStatic: true,
        render: {
          sprite: {
            texture: DONIcon,
            xScale: 1,
            yScale: 1,
          },
        },
      }
    );

    const surroundingNodes = [];
    const constraints = [];
    const radius = 120;
    for (let i = 0; i < 6; i++) {
      const angle = (i / 6) * 2 * Math.PI;
      const x = 200 / 2 + radius * Math.cos(angle);
      const y = 300 / 2 + radius * Math.sin(angle);
      const node = Matter.Bodies.circle(x, y, 30, {
        restitution: 0,
        render: {
          sprite: {
            texture: DONIcon,
            xScale: 1,
            yScale: 1,
          },
        },
      });
      surroundingNodes.push(node);
      let length = i % 2 === 0 ? 45 : 50; // Alternate length

      // Constraint to center node
      constraints.push(
        Matter.Constraint.create({
          bodyA: centerNode,
          bodyB: node,
          length: length,
          stiffness: 0.005,
          render: {
            visible: true,
            type: "line",
            lineWidth: 1,
            strokeStyle: "rgba(247,147,26, 0.5)",
          },
          damping: 0.01,
        })
      );

      // Constraint to adjacent node
      if (i > 0) {
        constraints.push(
          Matter.Constraint.create({
            bodyA: node,
            bodyB: surroundingNodes[i - 1],
            stiffness: 0.005,
            render: {
              visible: true,
              type: "line",
              lineWidth: 1,
              strokeStyle: "rgba(247,147,26, 0.5)",
            },
            damping: 0.01,
          })
        );
      }
    }

    // Connect last node to the first node
    constraints.push(
      Matter.Constraint.create({
        bodyA: surroundingNodes[0],
        bodyB: surroundingNodes[5],
        // length: 60,
        stiffness: 0.005,
        render: {
          visible: true,
          type: "line",
          lineWidth: 1,
          strokeStyle: "rgba(247,147,26, 0.5)",
        },
        damping: 0.01,
      })
    );

    // Add constraints first
    Matter.World.add(engine.world, constraints);

    // Then add nodes
    Matter.World.add(engine.world, [centerNode, ...surroundingNodes]);

    // Set timeScale (adjust as needed)
    // Matter.Engine.timeScale = 0.5; // Example: Normal speed
    Matter.Engine.run(engine);
    Matter.Render.run(render);

    let angleOffset = 0;

    // Apply a subtle oscillating force to the surrounding nodes
    Matter.Events.on(engine, "beforeUpdate", () => {
      angleOffset += 0.002; // Adjust this value to change the motion speed
      surroundingNodes.forEach((node, index) => {
        const angle = (index / 6) * 2 * Math.PI + angleOffset;
        const forceMagnitude = 0.0002; // Adjust this value to change the force magnitude
        const forceX = forceMagnitude * Math.cos(angle);
        const forceY = forceMagnitude * Math.sin(angle);
        Matter.Body.applyForce(node, node.position, { x: forceX, y: forceY });
      });
    });
    // Mouse interaction
    const mouse = Matter.Mouse.create(render.canvas);
    const mouseConstraint = Matter.MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    });
    Matter.World.add(engine.world, mouseConstraint);
    render.mouse = mouse;

    // Gyroscope effect
    const handleOrientation = (event) => {
      const beta = event.beta; // -180 to 180 (front-back)
      const gamma = event.gamma; // -90 to 90 (left-right)

      const forceX = (gamma / 90) * 0.02; // Scale down the effect
      const forceY = (beta / 180) * 0.02; // Scale down the effect

      surroundingNodes.forEach((node) => {
        Matter.Body.applyForce(
          node,
          { x: node.position.x, y: node.position.y },
          { x: forceX, y: forceY }
        );
      });
    };

    // window.addEventListener("deviceorientation", handleOrientation);

    // Cleanup on component unmount
    return () => {
      // window.removeEventListener("deviceorientation", handleOrientation);
      Matter.Render.stop(render);
      Matter.World.clear(engine.world);
      Matter.Engine.clear(engine);
      render.canvas.remove();
      render.textures = {};
    };
  }, []);

  return (
    <div className="bg-black">
      <div className="glassmorphism" ref={sceneRef} />
    </div>
  );
};

export default FloatingNodes;
