import React from "react";
import human from "../assets/newGovern.jpg";
import tick from "../assets/tick.svg";

const GovernanceSection = () => {
  return (
    <div className="h-full w-screen z-20 glassmorphism flex items-center">
      <div className=" flex-grow ">
        <div className="mx-6 lg:mx-0 lg:pr-56 w-full flex flex-row lg:justify-end gap-3 lg:gap-5 mb-5 lg:mb-10">
          <div className="text-3xl md:text-6xl text-white ">DONBIT </div>
          <div className="relative font-bold text-[#F6931B] text-3xl md:text-6xl w-fit">
            Governance
            <div className="absolute bottom-0.5 w-full rounded-full h-[8px] bg-[#F6931B]/30"></div>
          </div>
        </div>
        <div className="mx-6 lg:mx-32 flex lg:flex-row flex-col justify-center">
          <div className="z-30">
            <div className="text-white text-xs lg:text-base">
              The DONBIT Foundation will establish a
            </div>
            <div className="text-[#F6931B] mb-5 font-bold lg:text-3xl text-base ">
              DAO to facilitate governance.
            </div>
            <img
              src={human}
              alt=""
              className="lg:w-[600px] lg:h-[300px] w-[300px] h-[150px]"
            />
          </div>
          <div className="z-20 flex justify-end mx-6">
            <div className=" lg:translate-x-[-60px] translate-y-[-20px] orange-glassmorphism lg:w-[600px] w-[300px] glow text-xs lg:text-base">
              <div className="lg:ml-24 ml-6 my-4 lg:my-10">
                <div className="text-white lg:mb-6 mb-2">The DAO will:</div>
                <div className="flex flex-row lg:gap-5 gap-2 lg:mb-6 mb-2">
                  <img src={tick} alt="" className="w-4 h-4 lg:w-6 lg:h-6" />
                  <div>
                    <span className="text-[#F6931B] font-bold ">Manage</span>{" "}
                    <span className="text-white">the Governance Treasury</span>
                  </div>
                </div>
                <div className="flex flex-row lg:gap-5 gap-2 lg:mb-6 mb-2">
                  <img src={tick} alt="" className="w-4 h-4 lg:w-6 lg:h-6" />
                  <div>
                    <span className="text-white">Ensures</span>{" "}
                    <span className="text-[#F6931B] font-bold ">
                      Open-Source
                    </span>{" "}
                    <span className="text-white">Participation</span>
                  </div>
                </div>
                <div className="flex flex-row lg:gap-5 gap-2 lg:mb-6 mb-2">
                  <img src={tick} alt="" className="w-4 h-4 lg:w-6 lg:h-6" />
                  <div>
                    <span className="text-white">Promote</span>{" "}
                    <span className="text-[#F6931B] font-bold ">
                      Decentralized Decision-Making
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GovernanceSection;
