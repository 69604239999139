import React from "react";
import CoverPage from "../sections/CoverPage";
import VideoSection from "../sections/VideoSection";
import FeatureSection from "../sections/FeatureSection";
import ArchitectureSection from "../sections/ArchitectureSection";
import TokenomicsSection from "../sections/TokenomicsSection";
import GovernanceSection from "../sections/GovernanceSection";
import FAQ from "../sections/FAQ";
import bg from "../assets/bg.mp4";
import glow from "../assets/glow.png";
import eclipse from "../assets/Ellipse.png";

const HomePage = () => {
  return (
    <>
      <div className="relative h-screen flex flex-col overflow-hidden">
        {/* Background video */}
        <video
          autoPlay
          muted
          loop
          className="absolute inset-0 w-full h-full object-cover z-0"
        >
          <source src={bg} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Your content */}
        <CoverPage className="relative z-10" />
      </div>
      <VideoSection />

      <div className="relative bg-black lg:h-screen  flex flex-col overflow-hidden">
        <img src={glow} alt="" className="absolute vertical-align z-0 " />
        <img
          src={eclipse}
          alt=""
          className="h-full absolute eclipse-align z-20 object-cover "
        />
        <FeatureSection />
      </div>
      <ArchitectureSection />
      <div className="relative bg-black lg:h-screen overflow-hidden ">
        <img src={glow} alt="" className="absolute right-align  z-0 " />
        <TokenomicsSection />
      </div>
      <div className="relative bg-black lg:h-screen  overflow-hidden">
        <img src={glow} alt="" className="absolute vertical-align  z-0 " />
        <GovernanceSection />
      </div>
      <div className="relative bg-black lg:min-h-screen flex overflow-hidden">
        <img src={glow} alt="" className="absolute vertical-align  z-0 " />
        <FAQ />
      </div>
    </>
  );
};

export default HomePage;
