import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="h-screen ">
      <div className="flex flex-col h-full glassmorphism">
        <div className="flex lg:flex-row gap-3 mt-10 lg:mt-0 p-10 lg:mx-32">
          <div className="relative font-bold text-[#F6931B] mt-8 lg:mt-16 text-3xl md:text-6xl w-fit">
            Privacy Policy
            <div className="absolute bottom-0.5 w-full rounded-full h-[8px] bg-[#F6931B]/30"></div>
          </div>
        </div>
        <div className="flex-grow text-white overflow-auto lg:mx-32 mx-6 orange-glassmorphism fancy-scrollbar text-lg mb-5 format lg:format-lg">
          <h2 className="text-2xl font-bold mb-4">
            Effective date: 2024-06-24
          </h2>

          <h3 className="text-xl font-bold mb-2">Introduction</h3>
          <p className="mb-4">Welcome to Donbit.</p>
          <p className="mb-4">
            Donbit (“us”, “we”, “Donbit”) operates{" "}
            <a
              href="https://www.donbit.org"
              className="font-bold text-blue-400"
            >
              www.donbit.org
            </a>{" "}
            (hereinafter referred to as “Service”).
          </p>
          <p className="mb-4">
            Our Privacy Policy governs your visit to and explains how we
            collect, safeguard and disclose information that results from your
            use of our Service.
          </p>
          <p className="mb-4">
            We use your data to provide and improve Service. By using Service,
            you agree to the collection and use of information in accordance
            with this policy. Unless otherwise defined in this Privacy Policy,
            the terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions.
          </p>
          <p className="mb-4">
            Our Terms and Conditions (“Terms”) govern all use of our Service and
            together with the Privacy Policy constitutes your agreement with us
            (“agreement”).
          </p>

          <h3 className="text-xl font-bold mb-2">Definitions</h3>
          <p className="mb-4">
            <strong>SERVICE</strong> means the www.donbit.org website operated
            by Donbit.
          </p>
          <p className="mb-4">
            <strong>PERSONAL DATA</strong> means data about a living individual
            who can be identified from those data (or from those and other
            information either in our possession or likely to come into our
            possession).
          </p>
          <p className="mb-4">
            <strong>USAGE DATA</strong> is data collected automatically either
            generated by the use of Service or from Service infrastructure
            itself (for example, the duration of a page visit).
          </p>
          <p className="mb-4">
            <strong>COOKIES</strong> are small files stored on your device
            (computer or mobile device).
          </p>
          <p className="mb-4">
            <strong>DATA CONTROLLER</strong> means a natural or legal person who
            (either alone or jointly or in common with other persons) determines
            the purposes for which and the manner in which any personal data
            are, or are to be, processed. For the purpose of this Privacy
            Policy, we are a Data Controller of your data.
          </p>
          <p className="mb-4">
            <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any
            natural or legal person who processes the data on behalf of the Data
            Controller. We may use the services of various Service Providers in
            order to process your data more effectively.
          </p>
          <p className="mb-4">
            <strong>DATA SUBJECT</strong> is any living individual who is the
            subject of Personal Data.
          </p>
          <p className="mb-4">
            <strong>THE USER</strong> is the individual using our Service. The
            User corresponds to the Data Subject, who is the subject of Personal
            Data.
          </p>

          <h3 className="text-xl font-bold mb-2">
            Information Collection and Use
          </h3>
          <p className="mb-4">
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>

          <h4 className="text-lg font-semibold mb-2">
            Types of Data Collected
          </h4>
          <h5 className="text-md font-semibold mb-1">Personal Data</h5>
          <p className="mb-4">
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you (“Personal Data”). Personally identifiable information
            may include, but is not limited to:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, Country, State, Province, ZIP/Postal code, City</li>
            <li>Cookies and Usage Data</li>
          </ul>
          <p className="mb-4">
            We may use your Personal Data to contact you with newsletters,
            marketing or promotional materials and other information that may be
            of interest to you. You may opt out of receiving any, or all, of
            these communications from us by following the unsubscribe link.
          </p>

          <h5 className="text-md font-semibold mb-1">Usage Data</h5>
          <p className="mb-4">
            We may also collect information that your browser sends whenever you
            visit our Service or when you access Service by or through any
            device (“Usage Data”).
          </p>
          <p className="mb-4">
            This Usage Data may include information such as your computer’s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that you visit, the time and date
            of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>
          <p className="mb-4">
            When you access Service with a device, this Usage Data may include
            information such as the type of device you use, your device unique
            ID, the IP address of your device, your device operating system, the
            type of Internet browser you use, unique device identifiers and
            other diagnostic data.
          </p>

          <h5 className="text-md font-semibold mb-1">Tracking Cookies Data</h5>
          <p className="mb-4">
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>
          <p className="mb-4">
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyze our Service.
          </p>
          <p className="mb-4">
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>
          <p className="mb-4">Examples of Cookies we use:</p>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>Session Cookies:</strong> We use Session Cookies to
              operate our Service.
            </li>
            <li>
              <strong>Preference Cookies:</strong> We use Preference Cookies to
              remember your preferences and various settings.
            </li>
            <li>
              <strong>Security Cookies:</strong> We use Security Cookies for
              security purposes.
            </li>
            <li>
              <strong>Advertising Cookies:</strong> Advertising Cookies are used
              to serve you with advertisements that may be relevant to you and
              your interests.
            </li>
          </ul>

          <h5 className="text-md font-semibold mb-1">Other Data</h5>
          <p className="mb-4">
            While using our Service, we may also collect the following
            information: sex, age, date of birth, place of birth, passport
            details, citizenship, registration at place of residence and actual
            address, telephone number (work, mobile), details of documents on
            education, qualification, professional training, employment
            agreements, NDA agreements, information on bonuses and compensation,
            information on marital status, family members, social security (or
            other taxpayer identification) number, office location and other
            data.
          </p>

          <h3 className="text-xl font-bold mb-2">Use of Data</h3>
          <p className="mb-4">
            Donbit uses the collected data for various purposes:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>to provide and maintain our Service;</li>
            <li>to notify you about changes to our Service;</li>
            <li>
              to allow you to participate in interactive features of our Service
              when you choose to do so;
            </li>
            <li>to provide customer support;</li>
            <li>
              to gather analysis or valuable information so that we can improve
              our Service;
            </li>
            <li>to monitor the usage of our Service;</li>
            <li>to detect, prevent and address technical issues;</li>
            <li>to fulfil any other purpose for which you provide it;</li>
            <li>
              to carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection;
            </li>
            <li>
              to provide you with notices about your account and/or
              subscription, including expiration and renewal notices,
              email-instructions, etc.;
            </li>
            <li>
              to provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information;
            </li>
            <li>
              in any other way we may describe when you provide the information;
            </li>
            <li>for any other purpose with your consent.</li>
          </ul>

          <h3 className="text-xl font-bold mb-2">Retention of Data</h3>
          <p className="mb-4">
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies.
          </p>
          <p className="mb-4">
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer time periods.
          </p>

          <h3 className="text-xl font-bold mb-2">Transfer of Data</h3>
          <p className="mb-4">
            Your information, including Personal Data, may be transferred to –
            and maintained on – computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
          <p className="mb-4">
            If you are located outside United States and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United States and process it there.
          </p>
          <p className="mb-4">
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p className="mb-4">
            Donbit will take all the steps reasonably necessary to ensure that
            your data is treated securely and in accordance with this Privacy
            Policy and no transfer of your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of your data and other personal
            information.
          </p>

          <h3 className="text-xl font-bold mb-2">Disclosure of Data</h3>
          <p className="mb-4">
            We may disclose personal information that we collect, or you
            provide:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>Disclosure for Law Enforcement.</strong> Under certain
              circumstances, we may be required to disclose your Personal Data
              if required to do so by law or in response to valid requests by
              public authorities.
            </li>
            <li>
              <strong>Business Transaction.</strong> If we or our subsidiaries
              are involved in a merger, acquisition or asset sale, your Personal
              Data may be transferred.
            </li>
            <li>
              <strong>Other cases.</strong> We may disclose your information
              also:
              <ul className="list-disc list-inside">
                <li>to our subsidiaries and affiliates;</li>
                <li>
                  to contractors, service providers, and other third parties we
                  use to support our business;
                </li>
                <li>to fulfill the purpose for which you provide it;</li>
                <li>
                  for the purpose of including your company’s logo on our
                  website;
                </li>
                <li>
                  for any other purpose disclosed by us when you provide the
                  information;
                </li>
                <li>with your consent in any other cases;</li>
                <li>
                  if we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of the Company, our
                  customers, or others.
                </li>
              </ul>
            </li>
          </ul>

          <h3 className="text-xl font-bold mb-2">Security of Data</h3>
          <p className="mb-4">
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>

          <h3 className="text-xl font-bold mb-2">
            Your Data Protection Rights Under General Data Protection Regulation
            (GDPR)
          </h3>
          <p className="mb-4">
            If you are a resident of the European Union (EU) and European
            Economic Area (EEA), you have certain data protection rights,
            covered by GDPR.
          </p>
          <p className="mb-4">
            We aim to take reasonable steps to allow you to correct, amend,
            delete, or limit the use of your Personal Data.
          </p>
          <p className="mb-4">
            If you wish to be informed what Personal Data we hold about you and
            if you want it to be removed from our systems, please email us at
            editor@voiceofcrypto.online.
          </p>
          <p className="mb-4">
            In certain circumstances, you have the following data protection
            rights:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>
              the right to access, update or to delete the information we have
              on you;
            </li>
            <li>
              the right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete;
            </li>
            <li>the right to object;</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
