import React from "react";
import onchain_smart_contract from "../../assets/onchain/onchain_smart_contract.svg";
import onchain_l2_1 from "../../assets/onchain/onchain_l2_1.svg";
import onchain_l2_2 from "../../assets/onchain/onchain_l2_2.svg";
import onchain_l2_3 from "../../assets/onchain/onchain_l2_3.svg";
import onchain_l2_4 from "../../assets/onchain/onchain_l2_4.svg";
import onchain_l2_5 from "../../assets/onchain/onchain_l2_5.svg";
const OnChain = () => {
  return (
    <div className="flex flex-col items-center gap-2 animated-scale">
      <div className="text-center mb-4 text-[#F7931A] text-xl font-bold ">
        On Chain
      </div>
      <div className="h-full card card-glow w-fit flex flex-col items-center radial-bg ">
        <div className="text-center text-base w-3/4 leading-tight mb-2">
          BTC Ecosystem
        </div>
        <div className="flex flex-row lg:flex-col gap-4">
          <OnChainBTCL2 onchain_l2={onchain_l2_1} />
          <OnChainBTCL2 onchain_l2={onchain_l2_2} />
          <OnChainBTCL2 onchain_l2={onchain_l2_3} />
          <OnChainBTCL2 onchain_l2={onchain_l2_4} />
          <OnChainBTCL2 onchain_l2={onchain_l2_5} />
        </div>
      </div>
    </div>
  );
};

const OnChainBTCL2 = (props) => {
  return (
    <div className="flex flex-col gap-2 items-center">
      <div className="lg:hidden">
        <img src={onchain_smart_contract} className="w-10 h-10 " />
        <p className="w-10 text-center text-[10px]">Smart Contract</p>
      </div>
      <div>
        <img src={props.onchain_l2} className="w-12 h-12" />
      </div>
    </div>
  );
};
export default OnChain;
