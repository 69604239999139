import React from "react";
import EFTs from "../../assets/offchain/ETFs.svg";
import backend from "../../assets/offchain/backend.svg";
import web from "../../assets/offchain/web.svg";
import market from "../../assets/offchain/market.svg";
import events from "../../assets/offchain/events.svg";
import bank from "../../assets/offchain/bank.svg";
import exchange from "../../assets/offchain/exchange.svg";
import other from "../../assets/offchain/other.svg";
import { IconBase } from "react-icons";

const OffChain = () => {
  const chains = [
    {
      id: 1,
      name: "EFTs",
      icon: EFTs,
    },
    {
      id: 2,
      name: "Backend Systems",
      icon: backend,
    },
    {
      id: 3,
      name: "Web APIs",
      icon: web,
    },
    {
      id: 4,
      name: "Market Data",
      icon: market,
    },
    {
      id: 5,
      name: "Events Data",
      icon: events,
    },
    {
      id: 6,
      name: "Bank Payments",
      icon: bank,
    },
    {
      id: 7,
      name: "Exchanges",
      icon: exchange,
    },
    {
      id: 8,
      name: "Other Blockchains",
      icon: other,
    },
  ];
  return (
    <div className="h-full flex flex-col animated-scale mt-10 lg:mt-0">
      <div className="text-center mb-4 text-[#F7931A] text-xl font-bold ">
        Off Chain
      </div>
      <div className="flex-grow card card-glow radial-bg  ">
        <div className="text-center text-base lg:text-lg">
          Digital Broadcasting
        </div>
        <div className="text-center text-base lg:text-lg mb-4 lg:mb-8">
          {" "}
          Agent (DBA)
        </div>

        <div className="grid grid-cols-4 lg:grid-cols-2  gap-y-10 gap-x-2">
          {chains.map((chain) => (
            <div key={chain.id} className="flex flex-col items-center ">
              <img src={chain.icon} alt="" className="h-10 w-12" />
              <div className="text-xs text-center mt-1">{chain.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OffChain;
