import React from "react";
import Arrow from "./Arrow";
import onchain_user from "../../assets/onchain/onchain_user.svg";

const User = () => {
  return (
    <>
      <div className="relative lg:ml-8 mt-5 lg:mt-0">
        <div className="lg:absolute lg:translate-x-[-50%] lg:rotate-90 whitespace-nowrap text-white">
          HYBRID / MULTI-LEVEL SMART CONTRACT
        </div>
        <div className="ml-2">
          <Arrow />
        </div>
      </div>
      <div className="h-full w-fit flex flex-col items-center ml-2 justify-center">
        <div className="text-center text-lg w-3/4 leading-tight mb-4">User</div>
        <div className="flex flex-row lg:flex-col gap-4">
          <img src={onchain_user} className="w-10 h-10 " />
          <img src={onchain_user} className="w-10 h-10 " />
          <img src={onchain_user} className="w-10 h-10 " />
          <img src={onchain_user} className="w-10 h-10 " />
          <img src={onchain_user} className="w-10 h-10 " />
          <img src={onchain_user} className="w-10 h-10 hidden lg:block " />
          <img src={onchain_user} className="w-10 h-10 hidden lg:block" />
        </div>
      </div>
    </>
  );
};

export default User;
