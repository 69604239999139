import React from "react";
import BlueOcean from "../components/BlueOcean";

const TokenomicsSection = () => {
  return (
    <div className="h-full w-screen z-20 glassmorphism flex  items-center pt-10">
      <div className="flex-grow grid grid-cols-1 lg:grid-cols-2 mx-2">
        <div className="  w-full flex flex-col items-center justify-center gap-5 mb-10">
          {/* <div className="relative font-bold text-[#F6931B] text-lg md:text-6xl w-fit">
            Market Potential
            <div className="absolute bottom-0.5 w-full rounded-full h-[8px] bg-[#F6931B]/30"></div>
          </div> */}
          <div className="flex flex-row gap-5 p-4">
            <div className="relative font-bold text-[#F6931B] text-4xl lg:text-6xl w-fit">
              Market
              <div className="absolute bottom-0.5 w-full rounded-full h-[8px] bg-[#F6931B]/30"></div>
            </div>
            <div className="text-4xl lg:text-6xl text-white ">Potential </div>
          </div>

          <div className="relative mx-6 bg-[#271D12] max-w-[500px] lg:mt-12 text-white p-4 glow rounded-lg">
            <div className=" top-5 left-5 max-w-[500px] text-justify">
              Current market cap of oracles in all EVM chains is{" "}
              <span className="relative font-bold text-[#F6931B] text-lg  w-fit">
                $16.7 B
              </span>{" "}
              which is just 1.3% of the total market cap of all the chains
              except bitcoin.
            </div>
            <div className="flex flex-row justify-evenly text-xs lg:text-base">
              <div className="flex flex-row gap-2">
                <div className="flex flex-col items-center">
                  <div className="h-[200px] lg:[300px] flex items-end">
                    <div className="text-[#F6931B] bg-[#F6931B] h-[1%] w-[20px]"></div>
                  </div>
                  <div className="">Pyth</div>
                  <div className="lg:mt-2  text-gray-300 text-xs">$ 1.7B</div>
                </div>
                <div className="flex flex-col items-center">
                  <div className="h-[200px] lg:[300px] flex items-end">
                    <div className="text-[#F6931B] bg-[#F6931B] h-[10%] w-[20px]"></div>
                  </div>
                  <div>SOL</div>
                  <div className="lg:mt-2 text-gray-300 text-xs">$ 82 B</div>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col items-center">
                  <div className="h-[200px] lg:[300px] flex items-end">
                    <div className="text-[#F6931B] bg-[#F6931B] h-[2%] w-[20px]"></div>
                  </div>
                  <div>ChainLink</div>
                  <div className="lg:mt-2 text-gray-300 text-xs">$ 9.9 B</div>
                </div>
                <div className="flex flex-col items-center">
                  <div className="h-[200px] lg:[300px] flex items-end">
                    <div className="text-[#F6931B] bg-[#F6931B] h-[50%] w-[20px]"></div>
                  </div>
                  <div>ETH</div>
                  <div className="lg:mt-2 text-gray-300 text-xs">$ 453 B</div>
                </div>
              </div>
              <div className="flex flex-row gap-2 ">
                <div className="flex flex-col items-center">
                  <div className="h-[200px] lg:[300px] flex items-end">
                    <div className="text-[#F6931B] bg-[#F6931B] h-[40%] w-[20px]"></div>
                  </div>
                  <div>DONBIT</div>
                  <div className="lg:mt-2 text-gray-300 text-xs">$ ??</div>
                </div>
                <div className="flex flex-col items-center">
                  <div className="h-[200px] lg:[300px] flex items-end">
                    <div className="text-[#F6931B] bg-[#F6931B] h-[80%] lg:h-[100%] w-[20px]"></div>
                  </div>
                  <div>BTC</div>
                  <div className="lg:mt-2 text-gray-300 text-xs">$ 1,404 B</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BlueOcean />
      </div>
    </div>
  );
};

export default TokenomicsSection;
