import React from "react";

const TermsOfUse = () => {
  return (
    <div className="h-screen ">
      <div className="flex flex-col h-full glassmorphism">
        <div className="flex lg:flex-row gap-3 mt-10 lg:mt-0 p-10 lg:mx-32">
          <div className="relative font-bold text-[#F6931B] mt-8 lg:mt-16 text-3xl md:text-6xl w-fit">
            Terms of Use
            <div className="absolute bottom-0.5 w-full rounded-full h-[8px] bg-[#F6931B]/30"></div>
          </div>
        </div>
        <div className="flex-grow text-white overflow-auto lg:mx-32 mx-6 orange-glassmorphism fancy-scrollbar text-lg mb-5 format lg:format-lg">
          <p>Welcome to Donbit</p>

          <p>
            These terms of use ("Terms") govern your access to and use of the
            Donbit website (the "Website"). By accessing or using the Website,
            you agree to be bound by these Terms and our Privacy Policy. If you
            do not agree with any part of these Terms, you must not use our
            Website.
          </p>

          <h2 className="text-2xl font-bold mt-4">1. Use of the Website</h2>

          <h3 className="text-xl font-semibold mt-2">a. Eligibility</h3>
          <p>
            You must be at least 13 years old to use the Website. By agreeing to
            these Terms, you represent and warrant to us that you are at least
            13 years old.
          </p>

          <h3 className="text-xl font-semibold mt-2">b. License to Use</h3>
          <p>
            Subject to these Terms, we grant you a limited, non-exclusive,
            non-transferable, and revocable license to use our Website for your
            personal, non-commercial use.
          </p>

          <h3 className="text-xl font-semibold mt-2">
            c. Prohibited Activities
          </h3>
          <p>
            You may not use the Website for any illegal or unauthorized purpose.
            While using the Website, you must not violate any laws in your
            jurisdiction.
          </p>

          <h2 className="text-2xl font-bold mt-4">2. User Content</h2>

          <h3 className="text-xl font-semibold mt-2">a. Your Responsibility</h3>
          <p>
            You are solely responsible for the content and other materials you
            post on or through the Website, including but not limited to photos,
            videos, text, graphics, and personal information.
          </p>

          <h3 className="text-xl font-semibold mt-2">
            b. Ownership and Rights
          </h3>
          <p>
            You retain all rights in, and are solely responsible for, the User
            Content you post to Donbit.
          </p>

          <h3 className="text-xl font-semibold mt-2">
            c. License to Use Your Content
          </h3>
          <p>
            By posting User Content on or through the Website, you grant Donbit
            a worldwide, non-exclusive, royalty-free license to use, reproduce,
            modify, publicly display, and distribute your User Content in
            connection with operating and providing the Website and Content to
            you and to other users.
          </p>

          <h2 className="text-2xl font-bold mt-4">3. Intellectual Property</h2>

          <h3 className="text-xl font-semibold mt-2">a. Our Rights</h3>
          <p>
            All intellectual property rights in and to the Website and its
            original content, features, and functionality are and will remain
            the exclusive property of Donbit and its licensors.
          </p>

          <h3 className="text-xl font-semibold mt-2">b. Your Feedback</h3>
          <p>
            Any feedback, comments, or suggestions you may provide regarding
            Donbit or the Website is entirely voluntary and we will be free to
            use such feedback, comments, or suggestions as we see fit and
            without any obligation to you.
          </p>

          <h2 className="text-2xl font-bold mt-4">4. Termination</h2>
          <p>
            We may terminate or suspend your access to our Website immediately,
            without prior notice or liability, for any reason whatsoever,
            including without limitation if you breach the Terms.
          </p>

          <h2 className="text-2xl font-bold mt-4">5. Disclaimers</h2>

          <h3 className="text-xl font-semibold mt-2">a. No Warranties</h3>
          <p>
            The Website is provided on an "AS IS" and "AS AVAILABLE" basis.
            Donbit expressly disclaims all warranties of any kind, whether
            express or implied, including, but not limited to, implied
            warranties of merchantability, fitness for a particular purpose, and
            non-infringement.
          </p>

          <h3 className="text-xl font-semibold mt-2">
            b. Your Use is at Your Risk
          </h3>
          <p>
            Your use of the Website is at your sole risk. The Website may
            contain links to third-party websites or services that are not owned
            or controlled by Donbit.
          </p>

          <h2 className="text-2xl font-bold mt-4">
            6. Limitation of Liability
          </h2>
          <p>
            In no event shall Donbit, nor its directors, employees, partners,
            agents, suppliers, or affiliates, be liable for any indirect,
            incidental, special, consequential or punitive damages, including
            without limitation, loss of profits, data, use, goodwill, or other
            intangible losses, resulting from (i) your access to or use of or
            inability to access or use the Website; (ii) any conduct or content
            of any third party on the Website; (iii) any content obtained from
            the Website; and (iv) unauthorized access, use or alteration of your
            transmissions or content, whether based on warranty, contract, tort
            (including negligence) or any other legal theory.
          </p>

          <h2 className="text-2xl font-bold mt-4">7. Governing Law</h2>
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of your jurisdiction, without regard to its conflict of law
            provisions.
          </p>

          <h2 className="text-2xl font-bold mt-4">8. Changes</h2>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. What constitutes a material change will be
            determined at our sole discretion.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
