import React from "react";
import video from "../assets/videoholder.jpg";

const VideoSection = () => {
  return (
    <div className="h-full flex flex-col lg:flex-row">
      <div className="p-12 lg:p-2 text-white w-full lg:w-1/2 bg-[#261C12] flex flex-row justify-center items-center">
        <div className="w-3/4 lg:w-1/2">
          <div className="text-4xl lg:text-6xl ">What is</div>
          <div className="relative font-bold text-[#F6931B] text-4xl lg:text-6xl w-fit">
            DONBIT?
            <div className="absolute bottom-0.5 w-full rounded-full h-[8px] bg-[#F6931B]/30"></div>
          </div>
          <div className="text-sm lg:text-lg mt-6 lg:mt-2 text-gray-300">
            DONBIT is a Decentralized Oracle Network designed for Bitcoin and
            Bitcoin L2. DONBIT has a Hyperfocus on Bitcoin Ecosystem.
          </div>
        </div>
      </div>
      <div className="text-white w-full h-full lg:w-1/2 flex justify-center items-center">
        {/* <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/BBJa32lCaaY?si=vFCN_RGDsLplprAg&autoplay=1&controls=0&mute=1&loop=1"
          title="What is DONBIT?"
          frameBorder="0"
        ></iframe> */}
        <img src={video} className="w-full h-full object-cover" />
      </div>
    </div>
  );
};

export default VideoSection;
