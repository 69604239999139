import React from "react";

const Arrow = (props) => {
  const { size } = props;
  return (
    <div className="flex flex-col lg:flex-row items-center mx-2 my-5 lg:my-0">
      <div className="hidden lg:block left-arrow"></div>
      <div className="lg:hidden up-arrow"></div>
      <div
        className={`lg:h-0.5 ${size ? "lg:w-10" : "lg:w-5"} w-0.5 ${
          size ? "h-20" : "h-10"
        }  bg-[#F7931A]`}
      ></div>
      <div className="hidden lg:block right-arrow"></div>
      <div className="lg:hidden down-arrow"></div>
    </div>
  );
};

export default Arrow;
