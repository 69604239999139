import React from "react";
import BlogCard from "../components/BlogCard";
import { blogs } from "../sections/blogs";

const BlogsPage = () => {
  return (
    <div className="min-h-screen bg-black ">
      <div className="flex flex-col min-h-screen glassmorphism">
        <div className="flex lg:flex-row gap-3 mt-10 lg:mt-0 p-10 lg:mx-32">
          <div className="relative font-bold text-[#F6931B] mt-16 text-3xl md:text-6xl w-fit">
            Blogs
            <div className="absolute bottom-0.5 w-full rounded-full h-[8px] bg-[#F6931B]/30"></div>
          </div>
        </div>
        <div className="flex-grow mb-10 mx-6 lg:mx-32 grid grid-cols-1 lg:grid-cols-3 place-content-center gap-y-10 justify-between">
          {blogs.map((blog, index) => (
            <BlogCard blog={blog} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogsPage;
