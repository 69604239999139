import React from "react";
import smartContract from "../assets/smartContract.jpg";
import { Link } from "react-router-dom";

const BlogCard = ({ blog }) => {
  return (
    <Link
      to={`/blogs/${blog.id}`}
      className=" w-full lg:w-[350px] h-[450px] orange-glassmorphism flex flex-col"
    >
      <img
        src={blog.thumbnail}
        alt=""
        className="h-[200px] w-full object-cover rounded-lg"
      />
      <div className="flex flex-col flex-grow justify-between">
        <h2 class="text-xl font-bold text-white mt-4 line-clamp-5">
          {blog.title}
        </h2>
        <button
          type="button"
          class="py-2.5 px-5 me-2  text-sm font-medium text-[#F6931B] focus:outline-none bg-transparent rounded-lg border border-[#F6931B] hover:bg-[#F6931B] hover:text-white focus:z-10 focus:ring-4 focus:ring-[#F6931B] "
        >
          See More
        </button>
      </div>
    </Link>
  );
};

export default BlogCard;
