import React from "react";
import Canvas from "../Canvas";
import FloatingNodes from "../FloatingNodes";

const DON = () => {
  return (
    <div className="flex flex-col justify-center">
      <div className="text-base mb-0 lg:mb-5 text-center ">
        <div>Decentralized</div>
        <div>Oracle Network</div>
        <div>(DON)</div>
      </div>
      {/* <img src={DONIcon} alt="" /> */}
      {/* <Canvas /> */}
      <FloatingNodes />
    </div>
  );
};

export default DON;
