import React from "react";
import SOAIcon from "../../assets/soa.drawio.svg";

const SOA = () => {
  return (
    <div className="flex flex-col items-center gap-2 animated-scale">
      <div className="text-center mb-4 text-[#F7931A] text-xl font-bold">
        SOA Architecture
      </div>
      <div className="h-full w-fit flex flex-col items-center ">
        <img src={SOAIcon} className=" max-h-[450px]" />
      </div>
    </div>
  );
};

export default SOA;
